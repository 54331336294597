<template>
  <v-container>
    <v-card
      :loading="loading"
      class="mx-auto my-12 overlay kiosk-card"
    >
      <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-row>
            <v-col md="10">
              <div class="titles">Booking Details</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn class="white--text" @click="goToKiosk()" text
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="6" v-if="venueServices.length > 1">
              <v-select
                :items="venueServices"
                item-value="venue_service_id"
                item-text="service_name"
                label="Select Service*"
                return-object
                v-model="selectedService"
                required
                solo
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'Facility is required']"
                @change="initializeFacilityServices()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="bookingForm.facility_id"
                :items="facility_list"
                item-value="id"
                item-text="name"
                label="Select Facility*"
                required
                solo
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'Facility is required']"
                @change="getFacilityRentalTimes(),getFacilityUtils()"
              ></v-select>
            </v-col>
            <v-col sm="4" md="4">
              <date-field
                label="Booking date"
                :outlined="false"
                background-color="#F4F4F4"
                v-model="bookingForm.date"
                solo
                @change="getFacilityRentalTimes(), getFacilityUtils()"
              >
              </date-field>
            </v-col>
            <v-col sm="4" md="4">
              <v-select
                v-model="bookingForm.start_time"
                :items="getStartTime"
                label="Time Slot*"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'Opening Time Required']"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#F4F4F4"
                solo
                @change="getFacilityUtils()"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="slotMessage"
            ><v-col md="12"
              ><h2 class="text-warning text-center slot-message">
                {{ slotMessage }}
              </h2></v-col
            ></v-row
          >
          <v-row  v-if="products.length && slotMessage == ''">
            <v-col cols="12" class="customer-details-title" >Customer Details</v-col>
          </v-row>
          <CustomerBookingFormKiosk
            v-if="products.length && slotMessage == ''"
            :key="customerFormRefresh"
            :perCapacity="selectedService.per_capacity"
            :countries="countries"
            :customer="bookingForm"
            :field="field"
            :tags="tags"
            @setCustomerData="(data) => setCustomerData(data,null)"
          />
          <!-- </v-row> -->
          <v-row v-if="selectedService.per_capacity == 1 && bookingCustomersAddons.length > 0 && products.length && slotMessage == '' ">
            <div class="participant-details-box">
              <CustomerBookingFormKiosk
                v-for="(customer, index) in bookingCustomersAddons"
                :key="index"
                :addOn="1"
                :perCapacity="selectedService.per_capacity"
                :countries="countries"
                :customer="customer"
                :field="field"
                :tags="tags"
                @setCustomerData="(data) => setCustomerData(data,index)"
                @removeAddOnCustomer="removeAddOnCustomer(index)"
              />
            </div>
         </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="selectedService.per_capacity == 1 && bookingFormAdded < bookingFormTotal"
                class="add_btn pb-4"
                style="margin-top: -30px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="teal"
                      fab
                      x-small
                      dark
                      @click="addBookingFormCustomer"
                    >
                      <v-icon small>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="products.length && slotMessage == '' && bookingForm.start_time">
            <v-col cols="12" class="ticket-details-title" >Choose Your Ticket</v-col>
            <v-col lg="3" sm="3" md="3">
              <v-select
                solo
                :menu-props="{ bottom: true, offsetY: true }"
                label="Select Ticket"
                background-color="#F4F4F4"
                :items="products"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedProduct"
                @change="changeProduct"
                :rules="[(v) => !!v || 'Ticket is required']"
              >
              </v-select>
            </v-col>
            <v-col lg="3" md="3" sm="3">
              <v-text-field
                class="qty-field"
                v-if="products.length && slotMessage == '' && bookingForm.start_time"
                v-model="bookingForm.quantity"
                background-color="#F4F4F4"
                required
                solo
                append-icon="mdi-plus-circle"
                prepend-inner-icon="mdi-minus-circle"
                @click:append="plusQty"
                @click:prepend-inner="minusQty"
                label="Qty"
                type="number"
                readonly
                @keyup="changeProductQty"
                @change="changeProductQty"
                :rules="[(v) => !!v || 'Ticket qty is required']"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <!-- v-if="bookingForm.products" -->
        <v-row v-if="bookingForm.products && bookingForm.products.length > 0">
          <v-spacer></v-spacer>
          <v-col xl="1" lg="2" md="3" sm="6">
            <v-btn class="btn-reset" @click="resetBookingForm" text
              >Reset</v-btn
            >
          </v-col>
          <v-col lg="3" md="3" sm="6">
            <v-btn class="btn-confirm-booking" @click="submit" text
              >Confirm booking</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <KioskBookingConfirmationModal v-if="bookingConfirmDialog" :show="bookingConfirmDialog" @close="close" :orderId="orderId"/>
  </v-container>
</template>

<script>
import constantValue from "@/utils/constants";
import moment from "moment";
import CustomerBookingFormKiosk from "./CustomerBookingFormKiosk";
import KioskBookingConfirmationModal from "./KioskBookingConfirmationModal";
import bookingFields from "@/mixins/bookingFieldValidation";
export default {
  components: {
    CustomerBookingFormKiosk,KioskBookingConfirmationModal
  },
  mixins: [bookingFields],
  data() {
    return {
      orderId:null,
      bookingConfirmDialog: false,
      id: 0,
      customerFormRefresh: 0,
      slotMessage: "",
      categories: [],
      categoriesList: [{ name: "All", id: null }],
      productCombinations: [],
      constant: constantValue,
      venueServiceId: null,
      venue_service_id: null,
      bookingForm: {
        date: moment(new Date()).format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
        attendance: false,
        attendance_count: 1,
        opt_marketing: true,
        total_price: 0,
        price: 0,
        discount: null,
        promotion_code: null,
      },
      bookingFormTotal: 0,
      bookingFormAdded: 1,
      bookingCustomersAddons: [],
      bookingCustomersTotal: 0,
      pastTime: false,
      facility_id: "",
      service_name: "",
      valid: false,
      totalParticipants: 0,
      bookedCapacity: 1,
      capacity: 0,
      loading: false,
      startTimes: [],
      endTimes: [],
      facility_list: [],
      products: [],
      selectedService: {},
      selectedProduct: {},
    };
  },
  mounted() {
    if (this.$store.getters.getKioskServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadKioskVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getKioskServices.length) {
            let service = this.venueServices;
            this.selectedService = service[0];
            this.initializeFacilityServices();
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getKioskServices.length) {
        let service = this.venueServices;
        this.selectedService = service[0];
        this.initializeFacilityServices();
      }else {
        this.showError("No Kiosk Service Enabled");
        return
      }
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }

  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    venueServices() {
      return this.$store.getters.getKioskServices;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    getStartTime() {
      if (
        moment(this.bookingForm.date).format("YYYY-MM-DD") ==
        moment(new Date()).format("YYYY-MM-DD") && this.startTimes.length
      ) {
        let date = this.bookingForm.date;
        let filterTimes = this.startTimes.filter((time) => {
          let ctime = (date + ` ${time.value}`);
          let beginTime = moment(ctime).add((this.selectedService.min_booking_time), 'minutes').format("HH:mm:ss");
          if (beginTime == '00:00:00') {
            return true;
          } else {
            return beginTime >= moment().format('HH:mm:ss');
          }
        });
        return filterTimes;
      } else {
        return this.startTimes;
      }
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
  },
  methods: {
    initializeFacilityServices() {
      this.showLoader("Please wait ...");
      this.venueServiceId = this.selectedService.venue_service_id;
      this.bookingForm.venue_service_id = this.selectedService.venue_service_id;

      this.$store
        .dispatch("loadConfigurationsByVenueServiceId", this.selectedService.venue_service_id)
        .then((response) => {
          this.hideLoader();
          let data_facility = response.data.data.facilities;
          data_facility = data_facility.filter((item) => {
            return item.per_capacity == 1;
          })
          if (data_facility && data_facility.length > 0) {
            this.facility_list = data_facility;
            this.bookingForm.facility_id = data_facility[0].id;

            this.$nextTick(() => {
              this.getFacilityRentalTimes();
              this.$forceUpdate;
            });
          } else {
            this.slotMessage = "Facility not available";
          }
          this.venue_service_id = this.venueServiceId
          this.setFieldConfigurations();
        });

    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
        this.venueServiceId
      );
    },
    surfaces() {
      return this.$store.getters.getFacilitySurfaceByVenueServiceId(
        this.venueServiceId
      );
    },
    goToKiosk() {
      this.$router.push({ name: "KioskDashboard" }, () => {});
    },
    setCustomerData(data, objIndex = null) {
      if (objIndex == null) {
        this.$set(this.bookingForm, "customer_type", "normal");
        this.$set(this.bookingForm, "email", data.email);
        this.$set(this.bookingForm, "name", data.name);
        this.$set(this.bookingForm, "mobile", data.mobile);
        this.$set(this.bookingForm, "first_name", data.first_name);
        this.$set(this.bookingForm, "last_name", data.last_name);
        this.$set(this.bookingForm, "customer_id", data.customer_id);
        if(data.tag_id){
          this.$set(this.bookingForm, "customer_tag", data.tag_id);
        }
        if(data.gender){
          this.$set(this.bookingForm, "gender", data.gender);
        }
        if(data.dob){
          this.$set(this.bookingForm, "dob", data.dob);
        }
        if(data.country_id){
          this.$set(this.bookingForm, "country_id", data.country_id);
        }
        if (!data.first_name && data.name) {
          let name = data.name.replace(/\s\s+/g, " ");
          name = name.trim();
          let index = name.indexOf(" ");
          this.$set(this.bookingForm, "first_name", data.name);
          if (index != -1) {
            let first_name = name.substr(0, index).trim();
            let last_name = name.substr(index + 1).trim();
            this.$set(this.bookingForm, "first_name", first_name);
            this.$set(this.bookingForm, "last_name", last_name);
          } else {
            this.$set(this.bookingForm, "last_name", "");
          }
        }
      } else {
        this.$set(this.bookingCustomersAddons[objIndex], "customer_type", "normal");
        this.$set(this.bookingCustomersAddons[objIndex], "email", data.email);
        this.$set(this.bookingCustomersAddons[objIndex], "name", data.name);
        this.$set(this.bookingCustomersAddons[objIndex], "mobile", data.mobile);
        this.$set(this.bookingCustomersAddons[objIndex], "first_name", data.first_name);
        this.$set(this.bookingCustomersAddons[objIndex], "last_name", data.last_name);
        this.$set(this.bookingCustomersAddons[objIndex], "customer_id", data.customer_id);
         if(data.tag_id){
          this.$set(this.bookingCustomersAddons[objIndex], "customer_tag", data.tag_id);
        }
        if(data.gender){
           this.$set(this.bookingCustomersAddons[objIndex], "gender", data.gender);
        }
        if(data.dob){
          this.$set(this.bookingCustomersAddons[objIndex], "dob", data.dob);
        }
        if(data.country_id){
          this.$set(this.bookingCustomersAddons[objIndex], "country_id", data.country_id);
        }

        if (!data.first_name && data.name) {
          let name = data.name.replace(/\s\s+/g, " ");
          name = name.trim();
          let index = name.indexOf(" ");
          this.$set(this.bookingCustomersAddons[objIndex], "first_name", data.name);
          if (index != -1) {
            let first_name = name.substr(0, index).trim();
            let last_name = name.substr(index + 1).trim();
            this.$set(this.bookingCustomersAddons[objIndex], "first_name", first_name);
            this.$set(this.bookingCustomersAddons[objIndex], "last_name", last_name);
          } else {
            this.$set(this.bookingCustomersAddons[objIndex], "last_name", "");
          }
        }
      }
    },


    submit() {
      // this.orderId = 86314;
      // this.bookingConfirmDialog = true;

      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields to continue");
        return;
      }
      if (this.bookingForm.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      if (!this.bookingForm.facility_id || !this.bookingForm.venue_service_id) {
        this.showError("Please select facility");
        return;
      }
      if (this.selectedService.per_capacity == 1) {
        let count = this.bookingFormTotal;
        if (this.capacity < count) {
          this.showError("Trying to book more than available capacity");
          return;
        }
      }

      this.showLoader("Please wait ...");
      var formData = new FormData();
      for (let key in this.bookingForm) {
        if (
          this.bookingForm[key] != null &&
          !Array.isArray(this.bookingForm[key]) &&
          typeof this.bookingForm[key] != "object"
        ) {
          formData.append(`${key}`, this.bookingForm[key]);
        } else if (Array.isArray(this.bookingForm[key])) {
          this.bookingForm[key].forEach((data, index) => {
            if (!Array.isArray(data)) {
              for (let innerKey in data) {
                if (Array.isArray(data[innerKey])) {
                  if (
                    innerKey != "start_times" &&
                    innerKey != "end_times" &&
                    innerKey != "times"
                  ) {
                    data[innerKey].forEach((deepData, deepIndex) => {
                      formData.append(
                        `${key}[${index}][${innerKey}][${deepIndex}]`,
                        typeof deepData == "object"
                          ? JSON.stringify(deepData)
                          : deepData
                      );
                    });
                  }
                } else {
                  formData.append(
                    `${key}[${index}][${innerKey}]`,
                    data[innerKey]
                  );
                }
              }
            } else if (Array.isArray(data)) {
              data.forEach((innerData, innerIndex) => {
                formData.append(`${key}[${index}][${innerIndex}]`, innerData);
              });
            } else {
              formData.append(
                `${key}[${index}]`,
                typeof data == "object" ? JSON.stringify(data) : data
              );
            }
          });
        }
      }

      formData.append(`attendance_count`, 1);
      formData.append(`customer_type`, "normal");
      formData.append(`is_kiosk`, 1);
      if (this.selectedService.per_capacity == 1 && this.bookingCustomersAddons.length > 0) {
          for (let main in this.bookingCustomersAddons) {
            for (let keyInner in this.bookingCustomersAddons[main]) {
              if (
                this.bookingCustomersAddons[main][keyInner] != null &&
                !Array.isArray(this.bookingCustomersAddons[main][keyInner]) &&
                typeof this.bookingCustomersAddons[main][keyInner] != "object"
              ) {
                formData.append(
                  `addOnCustomers[${main}][${keyInner}]`,
                  this.bookingCustomersAddons[main][keyInner]
                );
              } else if (
                Array.isArray(this.bookingCustomersAddons[main][keyInner])
              ) {
                this.bookingCustomersAddons[main][keyInner].forEach(
                  (data, index) => {
                    if (!Array.isArray(data)) {
                      for (let innerKey in data) {
                        if (Array.isArray(data[innerKey])) {
                          if (
                            innerKey != "start_times" &&
                            innerKey != "end_times" &&
                            innerKey != "times"
                          ) {
                            data[innerKey].forEach((deepData, deepIndex) => {
                              formData.append(
                                `addOnCustomers[${main}][${keyInner}][${index}][${innerKey}][${deepIndex}]`,
                                typeof deepData == "object"
                                  ? JSON.stringify(deepData)
                                  : deepData
                              );
                            });
                          }
                        } else {
                          formData.append(
                            `addOnCustomers[${main}][${keyInner}][${index}][${innerKey}]`,
                            data[innerKey]
                          );
                        }
                      }
                    } else if (Array.isArray(data)) {
                      data.forEach((innerData, innerIndex) => {
                        formData.append(
                          `addOnCustomers[${main}][${keyInner}][${index}][${innerIndex}]`,
                          innerData
                        );
                      });
                    } else {
                      formData.append(
                        `addOnCustomers[${main}][${keyInner}][${index}]`,
                        typeof data == "object" ? JSON.stringify(data) : data
                      );
                    }
                  }
                );
              }
            }
          }
      }

      this.$http
        .post(`venues/facilities/bookings`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.hideLoader();

            this.orderId = data.order_id;
            this.bookingConfirmDialog = true;
            this.bookingFormTotal = 0;
            this.bookingFormAdded = 1;
            this.bookingCustomersAddons = [];
            this.bookingForm = {
              date: moment(new Date()).format("YYYY-MM-DD"),
              start_time: "",
              end_time: "",
              attendance: false,
              attendance_count: 1,
              opt_marketing: true,
              total_price: 0,
              price: 0,
              discount: null,
              promotion_code: null,
            };
            this.products = [];
            this.customerFormRefresh++;
            this.$forceUpdate();

          }
        })
        .catch((error) => {
          this.hideLoader();
          if (error.response && error.response.data && error.response.data) {
            this.slotMessage = error.response.data.message;
          }
         });
    },
    grandTotal() {
      var totalPrice = 0;
      if (this.bookingForm.products) {
        totalPrice = this.bookingForm.products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
        );
      }
      if (this.attendanceCustomers && this.attendanceCustomers.length) {
        this.attendanceCustomers.map((item) => {
          if (item.products) {
            if (item.products.length) {
              totalPrice += item.products.reduce(
                (a, b) => a + parseFloat(b.total_price),
                0
              );
            }
          }
        });
      }
      return totalPrice;
    },
    chooseRentalCombination() {
      this.showCombinationDialog = true;
    },
    getFacilityUtils() {
      if (!this.bookingForm.start_time) {
        return;
      }
      this.endTimes = [];
      this.bookingForm.products = [];
      if (
        this.bookingForm.date &&
        this.bookingForm.start_time &&
        this.bookingForm.facility_id
      ) {
        this.showLoader("Checking Availability");
        this.$http
          .get(
            `venues/facilities/bookings/utils?facility_id=${this.bookingForm.facility_id}&date=${this.bookingForm.date}&start_time=${this.bookingForm.start_time}&increment=${this.selectedService.time_increment}&min_booking_time=${this.selectedService.min_booking_time}&booking_id=${this.id}&per_capacity=${this.selectedService.per_capacity}&venue_service_id=${this.bookingForm.venue_service_id}&kiosk=1`
          )
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              if (data.length == 0) {
                this.showError(response.data.message);
                this.resetBookingForm();
                return;
              } else {
                this.slotMessage = "";
              }
              this.capacity = 0;
              if (
                data.is_enable_per_day_capacity &&
                data.capacity &&
                data.total_attendance
              ) {
                const remainingCapacity = data.capacity - data.total_attendance;
                this.capacity = remainingCapacity >= 1 ? remainingCapacity : 0;
              } else {
                this.capacity = data.capacity;
              }

              this.productCombinations = [];
              this.endTimes = data.end_times;
              this.bookingForm.start_time = data.start_time;
              this.bookingForm.end_time = data.end_time;
              this.categories = data.categories;
              this.categoriesList = [
                { name: "All", id: null },
                // { name: "Open Product", id: -1 },
                ...this.categories,
              ];
              if (
                data.facility_rentals.length > 0 &&
                this.id == 0 &&
                this.selectedService.per_capacity == 0
              ) {
                let rentalProducts = data.default_products;
                this.bookingForm.price = 0;
                this.bookingForm.total_price = 0;
                this.bookingForm.products = [];
                rentalProducts.forEach((rentalProduct) => {
                  this.bookingForm.price += rentalProduct.price;
                  this.bookingForm.total_price +=
                    rentalProduct.price +
                    (rentalProduct.quantity >= 1
                      ? rentalProduct.tax_amount *
                        parseFloat(rentalProduct.quantity)
                      : rentalProduct.tax_amount);
                  this.bookingForm.products.push({
                    product_id: rentalProduct.id,
                    product_type_id: rentalProduct.product_type_id,
                    price: rentalProduct.price,
                    name: rentalProduct.name,
                    tax: rentalProduct.tax_amount,
                    category_id: rentalProduct.category_id,
                    rental: true,
                    product_price: rentalProduct.product_price_when_overlapping
                      ? rentalProduct.product_price_when_overlapping
                      : rentalProduct.product_price,
                    quantity: rentalProduct.quantity,
                    total_price:
                      rentalProduct.price +
                      (rentalProduct.quantity >= 1
                        ? rentalProduct.tax_amount *
                          parseFloat(rentalProduct.quantity)
                        : rentalProduct.tax_amount),
                  });
                });
              } else {
                this.$http
                  .get(
                    `venues/facilities/bookings/participants?facility_id=${this.bookingForm.facility_id}&start_time=${this.bookingForm.start_time}&end_time=${this.bookingForm.end_time}&date=${this.bookingForm.date}`
                  )
                  .then((participans_response) => {
                    this.hideLoader();
                    if (
                      participans_response.status == 200 &&
                      participans_response.data.status == true
                    ) {
                      const data_participans_response = participans_response.data.data;

                      const uniqueParticipants = Array.from(
                          new Map(data_participans_response.map(item => [item.id, item])).values()
                      );

                      this.totalParticipants = uniqueParticipants.reduce(
                          (a, b) => a + parseFloat(b.attendance || 0),
                          0
                      );
                      this.bookedCapacity = this.totalParticipants;
                    }
                  });
                this.bookingForm.products = [];
                this.bookingForm.price = 0;
                this.bookingForm.total_price = 0;
              }
              if (this.selectedService.per_capacity == 1) {
                if (data.facility_rentals[0]) {
                  this.categoriesList.push({
                    name: "Tickets",
                    id: data.facility_rentals[0].category_id,
                  });
                }
              }
              if (data.facility_rentals.length) {
                this.products = data.facility_rentals;
                if (data.facility_rentals[0]) {
                  let rentalProducts = {
                    id: data.facility_rentals[0].category_id,
                    name: this.selectedService.per_capacity == 1 ? "Tickets" : "Rentals",
                    products: [
                      ...data.facility_rentals.map((item) => {
                        item.id = item.product_id;
                        item.rental = true;
                        return item;
                      }),
                    ],
                  };
                  this.categories.push(rentalProducts);
                }
              } else {
                this.products = [];
              }
              // this.bookingForm.opt_marketing = false;
              this.$refs.form.resetValidation();
              this.hideLoader();
              if (this.products.length) {
                this.bookingForm.product_id = this.products[0].id;
                this.selectedProduct = this.products[0];
                this.bookingForm.quantity = 1;
                this.setCustomerProduct();
              } else {
                this.selectedProduct = {};
                this.bookingForm.product_id = null;
              }
              this.$forceUpdate();
            } else {
              this.showError("Facility not available for this time");
              this.resetBookingForm();
            }
          })
          .catch(() => {
            this.hideLoader();
            this.slotMessage = "Slot not available";
            this.resetBookingForm();
          });
      } else {
        this.showError("Please fill facility, date and start time");
        return;
      }
    },
    updateBookingFormQuantity() {
      let old = this.bookingFormTotal;
      if (this.bookingForm.products) {
        let max = this.bookingForm.products.reduce(
          (acc, num) =>
            acc +
            (num.rental == true
              ? parseInt(num.quantity) * parseInt(num.participant_count)
              : 0),
          0
        );
        if (old > max && max < this.bookingFormAdded) {
          this.bookingFormAdded = max + 1;
        }
        this.bookingFormTotal = max;
        this.$forceUpdate();
      }
    },
    addBookingFormCustomer() {
      this.bookingCustomersAddons.push({
        attendance: false,
        attendance_count: 1,
        opt_marketing: true,
        total_price: 0,
        price: 0,
        mobile: null,
        email: null,
        name: null,
        first_name: null,
        last_name: null,
        customer_id: null,
        customer_type: 'normal',
        gender: null,
        country_id: null,
        dob: null,
      });
      this.bookingFormAdded++;
      this.$forceUpdate();
    },
    removeAddOnCustomer(index = null) {
      let removeAdded = 0;
      if (index == null) {
        for (let i = 0; i < this.selectedProduct.participant_count; i++){
          this.bookingCustomersAddons.splice(-1);
          removeAdded++;
        }
      } else {
        this.bookingCustomersAddons.splice(index, 1);
        removeAdded++;
      }
      let currentAdded = this.bookingFormAdded;
      if (currentAdded - removeAdded <= 0) {
        this.bookingFormAdded = 1;
      }else{
        this.bookingFormAdded = this.bookingFormAdded - removeAdded;
      }
      this.$forceUpdate();
    },
    resetBookingForm() {
      this.bookingForm = {
        date: moment(new Date()).format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
        attendance: false,
        attendance_count: 1,
        opt_marketing: true,
        total_price: 0,
        price: 0,
        discount: null,
        promotion_code: null,
      },
      this.bookingFormTotal = 0;
      this.bookingFormAdded = 1;
      this.bookingCustomersAddons = [];
      this.bookingCustomersTotal = 0;
      this.bookingForm.products = [];
      this.customerFormRefresh++;
      if (this.$store.getters.getKioskServices.length) {
        let service = this.venueServices;
        this.selectedService = service[0];
        this.initializeFacilityServices();
      }

    },
    changeProduct() {
      this.bookingForm.quantity = 1;
      this.bookingCustomersAddons = [];
      this.bookingFormTotal = 1;
      this.bookingFormAdded = 1;
       this.setCustomerProduct();
    },
    changeProductQty() {
      let qty = parseInt(this.bookingForm.quantity);
      if (qty > 10 || qty < 1) {
        this.bookingForm.quantity = 1;
      }
      this.setCustomerProduct();
    },

    setCustomerProduct() {
      let quantity =
        this.bookingForm.quantity && this.bookingForm.quantity > 0
          ? this.bookingForm.quantity
          : 1;

      if (this.selectedProduct.inventory_enable) {
        let sales = this.selectedProduct.sales ? this.selectedProduct.sales : 0;
        let totalQty = sales + parseFloat(quantity);
        parseFloat(this.selectedProduct.sales) + parseFloat(quantity) >=
          parseFloat(this.selectedProduct.actual_qty);
        if (parseFloat(this.selectedProduct.actual_qty) < totalQty) {
          if (
            this.selectedProduct.actual_qty - this.selectedProduct.sales ==
            0
          ) {
            this.showError("Product not available!");
          } else {
            this.showError(
              `Only ${
                this.selectedProduct.actual_qty - this.selectedProduct.sales
              } product available!`
            );
          }

          return;
        }
      }
      if (this.selectedProduct.id == null) {
        this.showError("Please add product");
        return;
      }

      if (this.selectedProduct.inventory_enable) {
        this.selectedProduct.sales += parseFloat(quantity);
      }
      let price = parseFloat(this.selectedProduct.price) * parseFloat(quantity);

      if (this.selectedProduct.title != null) {
        if (this.selectedProduct.tax_type_id == null) {
          this.showError("Please select tax");
          return;
        }
        if (this.selectedProduct.price == null) {
          this.showError("Please add price");
          return;
        }

        this.selectedProduct.id = null;
        this.selectedProduct.name = this.selectedProduct.title;

        if (this.selectedProduct.tax_type_id == 1) {
          this.selectedProduct.tax_amount =
            this.selectedProduct.total_price - this.selectedProduct.price;
        }
      }
      var obj = {
        product_id: this.selectedProduct.id ? this.selectedProduct.id : 0,
        price: price,
        name: this.selectedProduct.name,
        tax:
          (this.selectedProduct.tax_amount
            ? this.selectedProduct.tax_amount
            : 0) * quantity,
        quantity: parseFloat(quantity),
        product_type_id: this.selectedProduct.product_type_id,
        inventory_enable: this.selectedProduct.inventory_enable ? true : false,
        venue_service_id: this.bookingForm.venue_service_id,
        category_id: this.selectedProduct.category_id,
        participant_count: this.selectedProduct.participant_count,
        rental: this.selectedProduct.rental == true ? true : false,
        total_price:
          price +
          parseFloat(
            this.selectedProduct.tax_amount
              ? this.selectedProduct.tax_amount
              : 0
          ) *
            quantity,
      };
      this.bookingForm.products = [];
      this.bookingForm.products.push(obj);
      this.bookingForm.total_price = obj.total_price;
      this.$forceUpdate();
      this.updateBookingFormQuantity();
    },
    plusQty() {
      let qty = parseInt(this.bookingForm.quantity) + 1;
      if (qty >  (this.capacity - this.bookedCapacity) ){
        return;
      }
      if (qty < 10) {
        this.bookingForm.quantity = qty;
      } else {
        this.bookingForm.quantity = 10;
      }
      this.setCustomerProduct();
      this.$forceUpdate();
    },
    minusQty() {
      let qty = parseInt(this.bookingForm.quantity) - 1;
      if (qty >= 1) {
        this.bookingForm.quantity = qty;
        this.removeAddOnCustomer();
        this.setCustomerProduct();
        this.$forceUpdate();
      }
    },
    close() {
      this.bookingConfirmDialog = false;
      if (this.$store.getters.getKioskServices.length) {
        let service = this.venueServices;
        this.selectedService = service[0];
        this.initializeFacilityServices();
      }
    },
    getFacilityRentalTimes() {
      this.showLoader("Checking Availability");
        this.$http
          .get(
            `venues/kiosk/rental-facility-timing/?facility_id=${this.bookingForm.facility_id}&date=${this.bookingForm.date}&venue_service_id=${this.bookingForm.venue_service_id}`
          )
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              if (data.length > 0) {
                this.startTimes = data;
              } else {
                this.startTimes = [];
                this.slotMessage = "Slot not available";
              }

            }
          })
          .catch(() => {
            this.hideLoader();
            this.slotMessage = "Slot not available";
            this.resetBookingForm();
          });
    },

  },
};
</script>
<style>
.overlay {
  width: 100%;
  background-color: rgba(0,0,0,0.2) !important;
}
.kiosk-card .titles {
  font-size: 20px;
  color: #fff;
}
.kiosk-card .v-input.qty-field{
  width: 50%;
}
.kiosk-card .v-input.qty-field input{
  text-align: center;
}
.kiosk-card .btn-confirm-booking {
  padding: 6px !important;
  font-size: 14px !important;
  height: 48px !important;
  background-color: #86e2ff;
  font-weight:600;
  width:100%;
}
.kiosk-card .btn-reset {
  padding: 6px !important;
  font-size: 14px !important;
  height: 48px !important;
  background-color: #f7f7f7;
  width:100%;
}
/* Chrome, Safari, Edge, Opera */
.kiosk-card input::-webkit-outer-spin-button,
.kiosk-card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.kiosk-card input[type="number"] {
  -moz-appearance: textfield;
}
.kiosk-card .titles{
  padding-left: 0px;
}
.customer-details-title,.ticket-details-title{
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.participant-details-box {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  /* border: 1px solid #ccd; */
}
.slot-message{
  color: red;
}
.customer-box {
    padding: 20px 0px;
    border-bottom: 1px solid #afafaf;
    margin-bottom: 10px;
}
</style>

